import { fixVhUnits, throttle } from "@/utils/utils"

let init = () => {
    fixVhUnits()
    initResponsiveMenu()
}

let initResponsiveMenu = () => {
    let $body = document.querySelector("body")
    let $toggle = document.querySelector(".-js-menu-toggle")

    if (!$toggle) {
        return
    }

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if ($body.classList.contains("-no-scroll")) {
            $body.classList.remove("-no-scroll", "-show-menu")
        } else {
            $body.classList.add("-no-scroll", "-show-menu")
        }
    })

    let onScroll = () => {
        if (window.scrollY > 10) {
            $body.classList.add("-fixed-menu")
        } else {
            $body.classList.remove("-fixed-menu")
        }
    }

    window.addEventListener('scroll', throttle(onScroll, 10))
}

export default { init }
