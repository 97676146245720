import ResponsiveTable from "./table-responsive"

let init = () => {
    new ResponsiveTable(".-js-rwd-table", {
        forceResponsive: false,
        tableWrap: true,
    })
}

export default { init }
