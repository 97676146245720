import dayjs from "dayjs"
import customParseFormatPlugin from "dayjs/plugin/customParseFormat"
import utcPlugin from "dayjs/plugin/utc"

class DayReport {
    constructor() {
        this.$form = document.querySelector(".-js-day-report")
        this.$totalWork = document.querySelector(".-js-total-work")

        if (!this.$form || !this.$totalWork) {
            return
        }

        this.$date = document.querySelector("#report_date")
        this.date = dayjs(this.$date.value, "Y-m-d")

        if (!this.date) {
            return
        }

        this.status = this.$totalWork.getAttribute("data-status")
        this.$inputs = {
            type: document.querySelector("#report_type"),
            leaveHomeAt: document.querySelector("#report_leave_home_at"),
            arriveAgencyAt: document.querySelector("#report_arrive_agency_at"),
            startLunchAt: document.querySelector("#report_start_lunch_at"),
            endLunchAt: document.querySelector("#report_end_lunch_at"),
            leaveWorkAt: document.querySelector("#report_leave_work_at"),
        }

        dayjs.extend(utcPlugin)
        dayjs.extend(customParseFormatPlugin)

        this.handleType()
        this.calculateWorkTime()
    }

    handleType() {
        let callback = () => {
            this.enableField(this.$inputs["leaveHomeAt"])
            this.enableField(this.$inputs["arriveAgencyAt"])
            this.enableField(this.$inputs["startLunchAt"])
            this.enableField(this.$inputs["endLunchAt"])
            this.enableField(this.$inputs["leaveWorkAt"])

            if (this.isRest()) {
                this.disableField(this.$inputs["leaveHomeAt"])
                this.disableField(this.$inputs["arriveAgencyAt"])
                this.disableField(this.$inputs["startLunchAt"])
                this.disableField(this.$inputs["endLunchAt"])
                this.disableField(this.$inputs["leaveWorkAt"])
            } else if (this.isHalfRest()) {
                this.disableField(this.$inputs["startLunchAt"])
                this.disableField(this.$inputs["endLunchAt"])
            } else if (this.isHomeworking()) {
                this.disableField(this.$inputs["leaveHomeAt"])
                this.$inputs["leaveHomeAt"].value = this.$inputs["arriveAgencyAt"].value
            }
        }

        callback()

        this.$inputs["type"].addEventListener("change", callback)

        this.$inputs["arriveAgencyAt"].addEventListener("change", () => {
            if (this.isHomeworking()) {
                this.$inputs["leaveHomeAt"].value = this.$inputs["arriveAgencyAt"].value
            }
        })
    }

    calculateWorkTime() {
        let callback = () => {
            let dates = {}
            let totalWork = 0

            for (let name in this.$inputs) {
                dates[name] = dayjs(this.$inputs[name].value, "HH:mm")
            }

            if (this.isHalfRest()) {
                if (dates["arriveAgencyAt"].isValid() && dates["leaveWorkAt"].isValid() && dates["arriveAgencyAt"] <= dates["leaveWorkAt"]) {
                    totalWork += dates["leaveWorkAt"].diff(dates["arriveAgencyAt"])
                }
            } else {
                if (dates["arriveAgencyAt"].isValid() && dates["startLunchAt"].isValid() && dates["arriveAgencyAt"] <= dates["startLunchAt"]) {
                    totalWork += dates["startLunchAt"].diff(dates["arriveAgencyAt"])
                }

                if (dates["endLunchAt"].isValid() && dates["leaveWorkAt"].isValid() && dates["endLunchAt"] <= dates["leaveWorkAt"]) {
                    totalWork += dates["leaveWorkAt"].diff(dates["endLunchAt"])
                }
            }
            let time = dayjs.utc(totalWork)
            this.$totalWork.textContent = time.format("HH[h]mm")

            if (this.isTimeLimitExceeded(this.status, this.date, totalWork / 1000)) {
                this.$totalWork.classList.add("c-Form__error")
            } else {
                this.$totalWork.classList.remove("c-Form__error")
            }
        }

        for (let name in this.$inputs) {
            this.$inputs[name].addEventListener("change", callback)
        }
    }

    isTimeLimitExceeded(status, date, time) {
        let timeLimit = 7 * 3600

        if ("plein_4.5j" === status && 4 === date.format("d")) {
            timeLimit = 4 * 3600
        }

        return time > timeLimit
    }

    isHomeworking() {
        return "teletravail" === this.getType()
    }

    isRest() {
        let types = ["conge", "ferie", "malade"]

        return types.includes(this.getType())
    }

    isHalfRest() {
        let types = ["conge_matin", "conge_apres_midi", "malade_matin", "malade_apres_midi"]

        return types.includes(this.getType())
    }

    getType() {
        return this.$inputs["type"] ? this.$inputs["type"].value : ""
    }

    disableField($field) {
        $field.value = ""
        $field.setAttribute("readonly", "readonly")
        $field.parentNode.classList.add("-hide")
    }

    enableField($field) {
        $field.removeAttribute("disabled")
        $field.parentNode.classList.remove("-hide")
    }
}

export default {
    init: () => {
        new DayReport()
    },
}
