import "./theme.styl"

import DefaultLayout from "@/layouts/default/default.js"
import DayReport from "@/pages/day-report/day-report.js"
import Table from "@/components/table/table.js"
// Init before DOMContentLoaded
DefaultLayout.init()

// Init after DDOMContentLoadedOM
document.addEventListener("DOMContentLoaded", () => {
    Table.init()
    DayReport.init()
})
